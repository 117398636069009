import React from "react";
import { Link } from "gatsby";

import Scaffold from "../../../components/portfolio";

const Design = (props) => (
  <Scaffold pathname={props.location.pathname}>
    <h2>Website Design</h2>

    <h3>Overview</h3>

    <p>
      I've done freelance website design and development for various clients.
      These projects have been in various technologies depending on the needs of
      the client.
    </p>

    <Link to="/portfolio/design/gladstoneStreetMedicalClinic/">Next</Link>
  </Scaffold>
);

export default Design;
